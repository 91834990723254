<template>
    <div class="flex flex-row justify-start items-center mb-1 relative z-10">
        <component
            :is="returnRouter ? 'router-link' : 'a'"
            v-bind="componentProps"
            class="button-transparent rounded-full w-11 h-11 mr-2 shrink-0"
            :title="title"
        >
            <svg xmlns="http://www.w3.org/2000/svg" class="fill-current m-1.5 h-8 w-8" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
        </component>
        
        <h1 class="text-2xl text-medium-gray">{{ title }}</h1>
    </div>
</template>

<script setup>
    import { computed } from 'vue'

    const props = defineProps({
        title: {
            type: String,
            default: ''
        },
        returnUrl: {
            type: String,
            default: null
        },
        returnRouter: {
            type: Object,
            default: null
        }
    })

    const componentProps = computed(() => {

        if (props.returnRouter) {
            return {
                to: props.returnRouter
            }
        } 

        if (props.returnUrl) {
            return {
                href: props.returnUrl
            }
        }

        return {
            href: 'javascript:history.back()'
        }
    })
</script>